import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { Pie, PieChart } from 'recharts'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	type ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from '~/components/ui/chart'
import { Skeleton } from '~/components/ui/skeleton'
import { colors } from '~/config/colors'
import { useDescribe } from '~/hooks/admin-api/use-describe.hook'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'

export const OverstaysRecapStats = () => {
	const { t } = useTranslation()
	const overstaysDescribe = useDescribe('overstays')
	const overstays = useListEntityEntries('overstays', { page_size: 10000 })

	const overstaysStatuses = useMemo(() => {
		const filter = overstaysDescribe?.filters.find(
			({ name }) => name === 'status',
		)
		if (!filter) return []

		return filter.values as string[]
	}, [overstaysDescribe?.filters, t])

	const chartConfig = useMemo<ChartConfig>(() => {
		return overstaysStatuses.reduce(
			(acc, status, i) => ({
				...acc,
				[status]: {
					label: t(`values:values.status.${status}`),
					color: colors[i],
				},
			}),
			{},
		)
	}, [overstaysStatuses])

	const ordersStatuses = useMemo(() => {
		const ordersByStatus =
			overstays.data?.data?.reduce((acc, order) => {
				acc[order.status] = acc[order.status] || []
				acc[order.status].push(order)
				return acc
			}, {}) || {}

		return (
			overstaysStatuses
				?.map((name, i) => ({
					name,
					label: t(`values:values.status.${name}`),
					value: ordersByStatus[name as keyof typeof ordersByStatus]?.length,
					fill: chartConfig[name as keyof typeof chartConfig]?.color,
				}))
				.filter(({ value }) => !!value) || []
		)
	}, [overstaysDescribe?.filters, overstays])

	return (
		<Card>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Dépassements</CardTitle>

					<CardDescription>Dépassements par statut.</CardDescription>
				</div>

				<div className='flex min-w-[152px]'>
					<div className='relative z-30 flex flex-1 flex-col justify-center gap-1 border-t border-l px-6 py-4 text-left sm:border-t-0'>
						<span className='text-muted-foreground text-xs'>Total</span>

						<span className='flex flex-row items-end gap-1 font-bold text-lg leading-none sm:text-3xl'>
							{overstays.isLoading ? (
								<Skeleton className='h-6 w-full' />
							) : (
								overstays.data?.meta?.count || 0
							)}
						</span>
					</div>
				</div>
			</CardHeader>

			<CardContent className='px-2 sm:p-6'>
				{overstays.isLoading ? (
					<Skeleton className='h-[250px] w-full' />
				) : (
					<ChartContainer
						config={chartConfig}
						className='aspect-auto h-[250px] w-full'
					>
						<PieChart width={400} height={400}>
							<Pie
								nameKey='name'
								dataKey='value'
								isAnimationActive={false}
								data={ordersStatuses}
								cx='50%'
								cy='50%'
								outerRadius={80}
								labelLine
								label
							/>

							<ChartTooltip
								content={
									<ChartTooltipContent className='w-[150px] capitalize' />
								}
							/>

							<ChartLegend
								content={<ChartLegendContent className='capitalize' />}
							/>
						</PieChart>
					</ChartContainer>
				)}
			</CardContent>
		</Card>
	)
}
